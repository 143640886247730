//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TuoShiLOGO from "../../components/TuoShiLOGO.vue";
export default {
  components: { TuoShiLOGO },
  data() {
    return {
      uploader: [{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }],
      username: "",
      phone: "",
    };
  },
  methods: {
    // 提交
    onSubmit(values) {
      console.log("submit", values);
    },
  },
};
